button {
  border:0;
}
.btn {
  border:0;
  cursor: pointer;
  display:inline-block;
  position:relative;
  font-size: 18px;
  line-height: 1.4;
  font-family: $secondary-font-family;
  padding: 14.5px 75px 14.5px 20px;
  border-radius:0;
  background: #E34049;
  font-weight:600;
  color:#fff;
  position: relative;
  &:active {
    top:1px;
  }
  &:hover {
    text-decoration: none;
    color: #FFFFFF;
  }
  @media(max-width:900px) { font-size:17px; padding: 13.5px 75px 13.5px 17px; }
  @media(max-width:600px) { font-size:16px; padding: 12px 75px 12px 14px; }
  @media(max-width:450px) { font-size:15px; padding: 12px 75px 12px 10px;  }
  &:after {
   font-family: FontAwesome;
    content: "\f105";
    position: absolute;
    right:0;
    top:0;
    height:54px;
    width: 56px;
    line-height:18px;
    text-align:center;
    background:#D13841;
    color:#fff;
    font-size:32px;
    padding: 18px 0;
    @media(max-width:900px) { font-size:28px; height:50px; line-height:12px; }
    @media(max-width:600px) { font-size:24px; height:46px; width:46px; line-height:6px; }
    @media(max-width:450px) { font-size:21px; height:45px; width:45px; line-height:4px;  }
  }
}

.btn-red {
  @extend .btn;
}

.btn-blue {
  @extend .btn;
  background:#00A4C8;
  &:after {
    background:#038EAD;
  }
}

.back-btn {
  display:inline-block;

}
