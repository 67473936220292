.main-page-txt {
  .general-txt {
    table {
      border-left:1px #D2D2D2 solid;
      border-top:1px #D2D2D2 solid;
      font-size:12px;
      color:#555555;
      font-style: normal;
      font-weight: 700;
      width:100%;
      tbody {
        tr {
          td {
            border-bottom:1px #D2D2D2 solid;
            border-right:1px #D2D2D2 solid;
            padding:10px 15px;
            font-weight: 400;
            p {
              margin:0;
              font-size:12px;
              color:#555555;
              font-style: normal;
              font-weight: 400;
              &:first-child {
                font-weight:400;
                font-size:12px;
                margin:0;
                color:#555555;
              }
            }
            &:nth-child(1) {
              font-weight:700;
              p {
                font-weight:700;
                &:first-child {
                  font-weight:700;
                }
              }
            }
          }
          &:nth-child(even) {
            background-color:#FFFFFF;
          }
          &:nth-child(1) {
            background-color:#E3E8F0;
            color:#000000;
            td { }
          }
        }
      }
    }
  }
}