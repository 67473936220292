.main-page-txt {
  
    .main-page-txt-inner {
      padding-right:490px;
      h1 {
  
      }
      .general-txt {
  
      }
      @media(max-width:900px) {
        padding-right:0;
      }
    }
    .news-event-feed-wrap {
      top:-65px;
      width:378px;
      z-index: 1000;
      text-align:center;
      padding-left:70px;
      padding-right:70px;
      padding-top:35px;
      padding-bottom: 65px;
      background: #FFFFFF;
      border: 1px solid #DEDEDE;
      box-shadow: 0 0 4px 0 rgba(0,0,0,0.10);
      border-top:5px solid $red;
      @media(max-width:960px) {
        position: static;
        top:auto;
        margin:65px auto 0;
        width:100%;
        text-align:left;
        padding-left:35px;
        padding-right:35px;
        padding-top:45px;
        padding-bottom:35px;
      }
      h4 {
        font-size:24px;
        font-family:$primary-font-family;
        font-weight:700;
      }
      ul {
        li {
          position: relative;
          margin:25px 0;
          padding:25px 0 0;
          &:before {
            background:#DDDDDD;
            height:1px;
            content: " ";
            width:120px;
            position: absolute;
            left:calc(50% - 60px);
            top:0;
            display: block;
            @media(max-width:960px) {
              left:auto;
              width:100%;
            }
          }
          h5 {
            color:$red;
            font-family:$primary-font-family;
            font-weight:700;
            font-size:12px;
            text-transform: uppercase;
            margin:0;
          }
          h4 {
            font-weight:600;
            font-size:16px;
            margin:0;
            a {
              color:#414141;
            }
          }
        }
      }
      &.homepage-news-feed {
        position: absolute;
        right:0;
        top:-165px;
        @media(max-width:900px) {
          position: static;
          
        }
      }
    }
  }
  
  
  .calendar-foot {
    padding:105px 0 95px;
    background:#fff;
    @media(max-width:900px) { padding:85px 0 75px; }
    @media(max-width:600px) { padding:70px 0 60px; }
    @media(max-width:450px) { padding:55px 0 45px; }
    h2 {
      font-size:36px;
      font-family:$primary-font-family;
      font-weight:700;
      margin:0 0 30px;
      display: block;
      color:#055C8B;
      @media(max-width:900px) { font-size:32px; }
      @media(max-width:600px) { font-size:29px; }
      @media(max-width:450px) { font-size:27px; }
    }
  
    .calendar-foot-txt {
      display: inline-block;
      font-size:22px;
      line-height: 1.5em;
      color:#00A4C8;
      width:calc(100% - 675px);
      vertical-align: top;
      padding-right:55px;
      p {
        margin:0 0 30px;
        font-size: 20px;
        line-height: 27px;
      }
      @media(max-width:1050px) {
        width:100%;
        padding-right:0;
      }
      @media(max-width:900px) {
        font-size:19px;
        line-height: 1.5em;
        a.btn {
          font-size:17px;
        }
      }
      @media(max-width:600px) {
        font-size:17px;
        a.btn {
          font-size:16px;
        }
      }
      @media(max-width:450px) {
        font-size:16px;
        a.btn {
          font-size:15px;
        }
      }
    }
    .calendar-foot-items {
      display: inline-block;
      width:670px;
      vertical-align: top;
      @media(max-width:1050px) {
        width:100%;
        margin-top:50px;
      }
      ul {
        list-style: none;
        display: flex;
        @media(max-width:750px) {
          display: block;
        }
        li {
          width:33.333333%;
          display:flex;
          align-items:stretch;
          @media(max-width:750px) {
            display: block;
            width:100%;
            margin-bottom:45px;
          }
          vertical-align: top;
          .cal {
            width:40px;
            display: inline-block;
            vertical-align: top;
            margin-right:10px;
            .cal-mon {
              font-size: 12px;
              color: #727272;
              letter-spacing: 0;
              line-height: 14px;
              font-family: $standard-font-family;
              text-transform: uppercase;
              text-align: center;
            }
            .cal-day {
              font-family: $standard-font-family;
              font-size: 38px;
              color: #000000;
              letter-spacing: -0.3px;
              text-align: center;
            }
          }
          .item {
            width:calc(100% - 55px);
            display: inline-block;
            padding-left:15px;
            vertical-align: top;
            border-left:1px #D8D8D8 solid;
            padding-right:35px;
            position: relative;
            padding-bottom:20px;
             h4 {
              font-family: $standard-font-family;
              font-size: 16px;
              color: #055C8B;
              letter-spacing: 0;
              line-height: 21px;
              margin-bottom: 14px;
              font-weight:400;
               a {
                 color:#055C8B;
               }
            }
            .tag {
              position: absolute;
              bottom:0;
              left:15px;
            }
          }
        }
      }
    }
  }
  