.map {
  height:522px;
  width:100%;
}

.infoMarker {
  padding-top:10px;
  padding-bottom:10px;
  h4 {
    letter-spacing: 0;
    line-height: 1;
    margin: 0 0 10px;
    font-size: 17px;
    font-family: "proxima-nova";
    font-weight: 700;
  }
  p {
    color: #7E7E7E;
    font-size:14px;
    margin:0 0 10px;
  }
  a.btn-red {
    padding-top:8px;
    padding-bottom:8px;
    padding-left:10px;
    padding-right:50px;
    font-size:14px;
    &:after {
      height:34px;
      width:34px;
      padding:8px;
      font-size:22px;
      line-height: 18px;
      @media(max-width:900px) { 

        line-height: 18px;
      }
      @media(max-width:600px) { 

        line-height: 18px;
      }
      @media(max-width:450px) { 

      line-height: 18px;
      }

    }
  }
}