.news-slideshow {
  .slideshow {
    height:500px;
    .cycle-slideshow {
      .slide {
        height:500px;
        .inner-wrap {
          height:500px;
          padding-top:145px;
          .txt-wrap {
            max-width:625px;
          }
          h2 {
            margin:20px 0 0;
          }
          p.date {
            font-family: $standard-font-family;
            font-size: 16px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 34px;
            text-shadow: 0 0 15px rgba(0,0,0,0.76);
            font-weight:400;
          }
        }
      }
    }
  }
}