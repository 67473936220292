#mobile-nav-items {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 235px;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 50;
  text-align:left;
  ul {
    margin:10px 0;
    li {
      color:#fff;
      font-family: "proxima-nova";
      font-weight: 600;
      display: block;
      text-align:left;
      border-bottom:1px #183F5B solid;
      font-size:16px;
      // &:nth-child(1) {
      //   display: none;
      // }
      a, span {
        color:#fff;
        display:block;
        cursor: pointer;
        line-height: 1;
        padding:14px 20px;
        &:hover {
          text-decoration: none;
        }
        &.active {

        }
      }
      &.active {
        a, span {
          color:#f7e5ad;
        }
        ul.second { 
          display:block;
          li {
            a, span { 
              color:white;
              &.active {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    &.second {
      display: none;
      margin-top:0;
      li {
        border-bottom:0;
        display: flex;
        padding:0 24px;
        text-transform: none;
        margin-bottom:5px;
        span {
          padding:0;
          margin-right:8px;
          display:inline-block;
          position: relative;
          top:7px;
        }
        a {
          padding:0;
          font-size:13px;
          padding-top:8px;
          padding-bottom:8px;
          display: inline-block;
          border-bottom:0;
          line-height:1.35;
        }
        &:nth-child(1) {
          a {
            padding-top:10px;
          }
        }
      }
    }
    &.open {
      display: block;
    }
  }
}
