.inner-wrap {
  max-width:$container + px;
  width:90%;
  margin:auto;
}

.main-page-txt {
  background:#FBFBFB;
  min-height:450px;
  @media(max-width:600px) { min-height:0; }
  .inner-wrap {
    position: relative;
    margin-top:100px;
    margin-bottom:100px;
    &.stickem-container {
      justify-content: space-between;
      align-content: space-between;
      align-items: flex-start;
      display:flex;
      // REMOVE STICKY NAV AT 960PX
      @media(max-width:960px) {
        flex-direction: column;
      }
    }
    @media(max-width:900px) { margin-top:80px; margin-bottom:80px;  }
    @media(max-width:600px) { margin-top:65px; margin-bottom:65px;  }
    @media(max-width:450px) { margin-top:50px; margin-bottom:50px;  }
  }
  h1 {
    font-size: 36px;
    color: #055C8B;
    letter-spacing: 0;
    font-weight:700;
    margin:0;
    @media(max-width:900px) {
      font-size:30px;
      line-height: 1.5em;
    }
    @media(max-width:600px) {
      font-size:27px;
      line-height: 1.5em;
    }
    @media(max-width:450px) {
      font-size:24px;
      line-height: 1.5em;
    }
  }
  .general-txt {
    margin:10px 0;
    font-size: 16px;
    color: #555555;
    letter-spacing: 0;
    line-height:2em;
    @media(max-width:600px) { font-size: 15px; line-height:2em; }
    @media(max-width:450px) { font-size: 14px; line-height:2em; }
    h2 {
      font-size: 22px;
      color: #555555;
      letter-spacing: 0;
      display: block;
      margin-top:45px;
      margin-bottom:20px;
      line-height:1.25;
      @media(max-width:600px) { font-size:20px; }
      @media(max-width:450px) { font-size:18px; }
    }
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #144672;
      letter-spacing: 0;
      line-height: 1.5;
    }
    p {
      margin:0 0 1.5em;
      &:first-child {
        font-size: 25px;
        color: #00A4C8;
        letter-spacing: 0;
        margin:0 0 1em;
        @media(max-width:900px) { font-size: 23px; line-height: 1.5em; }
        @media(max-width:600px) { font-size: 20px; line-height: 1.5em; }
        @media(max-width:450px) { font-size: 18px; line-height: 1.5em; }
      }
      a {
        color: #00A4C8;
        text-decoration: underline;

      }
    }
    ul {
      padding:0;
      margin: 0 20px 1.5em;
      list-style: disc;
      color:#555555;
      li {
        margin:0;
        padding:0;
      }
    }

    &.columns {
      ul {
        list-style-type: none;
        margin:0 0 35px;
        column-count: 3;
        font-style: normal;
        font-weight: 400;
        color:#00A4C8;
        font-size:15px;
        margin-top:10px;
        column-gap:35px;
        @media(max-width:600px) { column-count: 2; }
        @media(max-width:450px) { column-count: 1; }
        li {
          position: relative;
          padding-left:10px;
          line-height:1.35;
          margin:0 0 15px;
          &:before {
             content: "\2022";
             position: absolute;
             top:0;
             left:0;

          }
        }
      }
    }
  }
}

ul.docs-wrap {
  list-style: none;
  display:block;
  margin:50px 0;
  padding:0;
  li {
    display:block;
    padding:0;
    margin:35px 0 0;
    &:nth-child(1) {
      margin-top:0;
    }
    a {
      display: block;
      color:#055C8B;
      padding-left:110px;
      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
      &:before {
        font-family: FontAwesome;
        content: "\f0f6";
        display: inline-block;
        padding-right: 3px;
        vertical-align: middle;
        width:90px;
        height:90px;
        line-height:90px;
        font-size:24px;
        background:#E1EBF1;
        position: absolute;
        left:0;
        top:0;
        text-align:center;
        font-size:41px;
      }
      &[href$=".doc"],
      &[href$=".docx"] {
        &:before {
          content: "\f1c2";
        }
      }
      &[href$=".pdf"] {
        &:before {
          content: "\f1c1";
        }
      }
      &[href$=".xsl"],
      &[href$=".xlsx"] {
        &:before {
          content: "\f1c3";
        }
      }
      .file-title {
        font-family:$standard-font-family;
        color:#055C8B;
        font-size:16px;
        font-weight:400;
        display: inline-block;
        line-height: 1em;
        margin-top:5px;
      }
      .file-name {
        font-family:$standard-font-family;
        font-weight:400;
        color:#555555;
        font-size:12px;
        display: block;
        line-height: 1em;
        margin:5px 0 12px;
      }
      .btn {
        font-size:13px;
        padding-top:10.5px;
        padding-bottom:10.5px;
        padding-right:60px;
        &:after {
          font-size:22px;
          height:34px;
          width:34px;
          padding:8px 0;
          @media(max-width:900px) { line-height:16px; }
          @media(max-width:600px) { line-height:15px; }
        }
      }
    }
  }
}

.calender-details-template {
  .calendar-foot {
    border-top:1px solid #DDDDDD;
  }
}

.subpage-wrap {
  //padding-right:470px;
  width:calc(100% - 471px);
  display:inline-block;
  vertical-align: top;
  // REMOVE STICKY NAV
  @media(max-width:960px) {
    //padding-right:0;
    width:100%;
    display: block;
  }
}

.stickem-container {
  position: relative;
}

.main-page-txt {
  .sub-menu-wrap {
    width:380px;
    vertical-align: top;
    display:inline-block;
    padding:30px 40px;
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.10);
    border: 1px solid #DEDEDE;
    border-top: 6px solid #E34049;

    @media(min-width:960px) and (max-width:1300px) {
      position: absolute;
      right:0;
      top:10px !important;
    }
    @media(max-width:960px) {
      position: static;
      top:auto;
      width:100%;
      margin-top:100px;
      margin-right:auto;
      margin-left:auto;
    }
    @media(max-width:600px) {
      margin-top:75px;
    }
    @media(max-width:450px) {
      padding:30px 30px;
      margin-top:50px;
    }
    h3 {
      font-family:$primary-font-family;
      font-size:24px;
      font-weight:700;
      color:#414141;
    }
    ul {
      list-style: none;
      margin:20px 0 0;
      padding:0;
      li {
        display: block;
        border-top:1px solid #DDDDDD;
        font-size:15px;
        color:#414141;
        font-weight:500;
        a {
          color:#7E7E7E;
          display: block;
          padding:12px 25px 12px 0;
          position: relative;
          line-height: 1.3em;
          &:after {
            content: "\f054";
            font-family: FontAwesome;
            position: absolute;
            right:2px;
            top:11px;
            color:#D0403D;
          }
          &.active {
            color:#D0403D;
          }
        }
      }
    }
    
    &.news-event-feed-wrap {
      ul {
        li {
          border-top:0;
          a {
            padding:0;
            &:after {
              display: none;
            }
          }
        }
      }
    }
    &.stickit {
      margin-left: 780px;
      position: fixed;
      top: 20px;
      @media(min-width:960px) and (max-width:1300px) {
        margin-left:auto;
        right:5%;
        top:20px;
      }
    }
    &.stickit-end {
      top: auto;
      bottom: 20px;
      position: absolute;
      right: 0;
      @media(min-width:960px) and (max-width:1300px) {
        top:auto !important; 
      }
    }
    // REMOVE STICKY NAV
    @media(max-width:960px) {
      &.stickit {
        margin-left:auto;
        position: static;
        top: 0;
      }
      &.stickit-end {
        top: auto;
        bottom: 0;
        position: static;
        right: 0;
      }
    }
  }
}

/*
@media(min-width:900px) {
  #stick.sub-menu-wrap.stuck {
    position: fixed;
    top: 25px;
  }
}*/

ul.listNews {
  margin:0;
  padding:0;
  list-style: none;
  li {
    border-top:1px solid #D8D8D8;
    margin:0 35px;
    padding-top:5px;
    padding-bottom:5px;
    @media(max-width:900px)  { margin:0 25px; }
    @media(max-width:600px)  { margin:0 15px; }
    @media(max-width:450px)  { margin:0 10px; }
    h2 {
      font-size: 50px;
      color: #055C8B;
      letter-spacing: 0;
      line-height:1;
      font-family:$primary-font-family;
      font-weight:700;
      margin:10px 0;
      a {
        color:#055C8B;
      }
      @media(max-width:1100px) { font-size: 45px; }
      @media(max-width:900px) { font-size: 40px; }
      @media(max-width:600px) { font-size: 37px; }
      @media(max-width:450px) { font-size: 33px;}
    }
    h3 {
      font-family:$primary-font-family;
      font-weight:700;
      font-size: 24px;
      color: #055C8B;
      letter-spacing: 0;
      line-height: 1;
      a {
        color:#055C8B;
      }
      @media(max-width:1100px) { font-size: 22px; }
      @media(max-width:900px) { font-size: 20px; }
      @media(max-width:600px) { font-size: 17px; }
      @media(max-width:450px) { font-size: 15px; }
    }
    h4 {
      font-size: 16px;
      font-family:$primary-font-family;
      font-weight:700;
      a {
        color:#055C8B;
      }
      @media(max-width:600px) { font-size: 15px; }
      @media(max-width:450px) { font-size: 14px;  }
    }
    .tag {
      display: inline-block;
      margin-right:7px;
    }
    .date {
      font-size: 13px;
      color: #B4AFAF;
      letter-spacing: 0;
      line-height: 34px;
    }
    .summary {
      font-size: 16px;
      color: #555555;
      letter-spacing: 0;
      line-height:1.5em;
      margin:15px 0;
      @media(max-width:900px) { font-size: 15px; }
      @media(max-width:600px) { font-size: 14px; }
      @media(max-width:450px) { font-size: 13px; }
    }
    a.btn {
      font-size:14px;
      line-height: 1em;
      padding:15px 62px 15px 15px;
      @media(max-width:900px) { font-size: 13px; }
      @media(max-width:600px) { font-size: 12px; }
      @media(max-width:450px) { font-size: 11px; }
      &:after {
        padding:12px 0;
        height:44px;
        font-size:27px;
        width:45px;
        @media(max-width:900px) { width:43px; height:43px; font-size: 23px; padding:14px 0; }
        @media(max-width:600px) { width:42px; height:42px; font-size: 20px; padding:18px 0;  }
        @media(max-width:450px) { width:41px; height:41px; font-size: 18px; }
      }
    }
    &:nth-child(1) {
      background:#fff;
      padding-top:50px;
      padding-bottom:50px;
      border: 1px solid #DEDEDE;
      box-shadow: 0 0 4px 0 rgba(0,0,0,0.10);
      padding-left:35px;
      padding-right:35px;
      margin:0;
      @media(max-width:900px)  { padding:35px 25px; }
      @media(max-width:600px)  { padding:25px 20px; }
      @media(max-width:450px)  { padding:20px 15px; }
    }
    &:nth-child(2) { border-top:0; }
    &:nth-child(2),
    &:nth-child(3) {
      padding-top:50px;
      padding-bottom:50px;
    }
  }
}
