#page-wrap {
  .foot-featured-items {
    display:flex;
    position: relative;
    z-index: 2000;
    @media(max-width:900px) {
      display:block;
    }
    .col {
      width:33.33333%;
      @media(max-width:900px) {
        width:100%;
      }
      .img-wrap {
        display: block;
        width:100%;
        height:320px;
        background-repeat:no-repeat;
        background-size:cover;
        background-position: center;
        position: relative;
        img {
          display: none;
        }
        @media(max-width:900px) {
          height:auto;
          img {
            display: block;
            width:100%;
          }
        }
        a.title {
          color: #DDAD00;
          letter-spacing: 0;
          font-family:$primary-font-family;
          font-size: 24px;
          line-height: 1em;
          padding:15px;
          display: inline-block;
          position: absolute;
          left:0;
          bottom:30px;
          font-weight:700;
          &:hover {
            text-decoration: none;
          }
          &:active {
            top:auto;
            bottom:29px;
          }
          @media(max-width:900px) { font-size: 22px; }
          @media(max-width:600px) { font-size: 20px; }
          @media(max-width:450px) { font-size: 18px; }
        }
      }
      .txt-wrap {
        padding:50px;
        font-size: 14px;
        color: #555555;
        line-height: 1.5;
        @media(max-width:900px) { padding:40px; }
        @media(max-width:600px) { font-size:13px; padding:30px; }
        @media(max-width:450px) { font-size:12px; padding:25px; }
        h3 {
          font-family:$primary-font-family;
          font-weight:700;
          font-size: 24px;
          margin:0 0 20px;
          @media(max-width:900px) { font-size: 22px; }
          @media(max-width:600px) { font-size: 20px; }
          @media(max-width:450px) { font-size: 18px; }
        }
        ul {
          list-style: none;
          margin:20px 0 0;
          padding:0;
          li {
            margin:0;
            padding:0;
            font-family:$primary-font-family;
            font-weight:700;
            font-size:12px;
            text-transform: uppercase;
            span {
              display: inline-block;
              margin-right:7px;
            }
            a {

            }
          }
        }
      }
      &:nth-child(1) {
        background-color:rgba(224,180,21,0.15);
        h3 {
          color:#DDAD00;
        }
        .img-wrap {
          .title {
            background-color:#FBF4DD;
            color:#DDAD00;
          }
        }
        .txt-wrap {
          ul {
            li {
              a {
                color:#DEAD00;
              }
            }
          }
        }
      }
      &:nth-child(2) {
        background-color:rgba(227,63,73,0.17);
        h3 {
          color:#E34049;
        }
        .img-wrap {
          .title {
            background-color:#FADEE0;
            color:#E34049;
          }
        }
        .txt-wrap {
          ul {
            li {
              a {
                color:#E34049;
              }
            }
          }
        }
      }
      &:nth-child(3) {
        background-color:rgba(5,92,139,0.12);
        h3 {
          color:#055C8B;
        }
        .img-wrap {
          .title {
            background-color:#E1EBF1;
            color:#055C8B;
          }
        }
        .txt-wrap {
          ul {
            li {
              a {
                color:#055C8B;
              }
            }
          }
        }
      }
    }
  }

  footer {
    padding:47px 0;
    background:#0E3550;
    color:#fff;
    font-family:$secondary-font-family;
    font-size:15px;
    .col-half {
      &:nth-child(1) {
        float:left;
        background:url('../imgs/footer_logo.png') left center no-repeat;
        padding-left:60px;
        a {
          margin-right:17px;
        }

        .mobile-divider {
          display: none;
          @media(max-width:450px) {
            display:block;
            width:100%;
            height:2px;
          }
        }

        @media(max-width:900px) {
          float:none;
          background-position: center top;
          width:100%;
          display: block;
          text-align:center;
          margin-bottom:35px;
          padding-top:60px;
          padding-left:0;
        }
      }
      &:nth-child(2) {
        float:right;
        text-align:right;

        @media(max-width:900px) {
          float:none;
          width:100%;
          display: block;
          text-align:center;
        }
        a {
          text-decoration: underline;
          &:hover {
            color:#fff;
          }
        }
      }
      i {
        color:#EEB561;
        display: inline-block;
        margin-right:5px;
        font-size:19px;
      }
      strong {
        display: block;
      }
      a {
        color:rgba(#FFFFFF, 0.5);
        display: inline-block;
      }
      span {
        display: block;
        color:rgba(#FFFFFF, 0.5);
        @media(max-width:450px) {
          padding:7px 0 0;
        }
        span {
          display: inline-block;
        }
      }
    }
  }
}
