
$primary-font-family:	"proxima-nova";
$secondary-font-family: "proxima-nova";
$standard-font-family: "adelle";

$container: 1160;

$red: #E34049;
$blue: #00A4C8;

$break-small: 400px;
$break-large: 1130px;
$tablet: 1080px;
$mobile-nav: 1240px;
