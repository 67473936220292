
form {
	width:100%;
	button.btn {
		display:inline-block;
		border-radius:3px;
		font-weight:100;
		padding:18px 20px;
		line-height:1em;
	}
}
label { display:none; }
input, textarea {
	font-family:$standard-font-family;
	width:100%;
  border-radius: 3px;
	padding:10px;
	border:0;
	margin-bottom:18px;
	border: 1px solid #DDDDDD;
	color: #000;
}
textarea {
	min-height:170px
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
	-webkit-appearance:none;
}

button {
	font-family:$secondary-font-family;
	text-transform: uppercase;
	width:100%;
	font-size:20px;
	padding:15px;
	display: inline-block;
	width: auto;
	line-height: 1em;
}

#validation-msg {
	color:#333;
	margin:0 auto;
	width:100%;
	font-family:$primary-font-family;
	text-align:center;

	background: #FBF4DD;
	padding: 15px 10px;
	display: block;
	letter-spacing: 0;
	font-family: "proxima-nova";
	line-height: 1;
	font-weight: 700;

}

.select_style {
	overflow: hidden;
	display: inline-block;
	position: relative;
	cursor: pointer;
  background: #fff;
  border-radius: 3px;
  font-family:$standard-font-family;
  font-size: 18px;
  width:100%;
	position:relative;
	border: 1px solid #DDDDDD;
	&:after {
		content: "\f0d7";
		font-family: FontAwesome;
		position: absolute;
		color:#848694;
		top:14px;
		right:14px;
		font-size: 16px;
	}
	select {
	  -moz-appearance: none;
	  -webkit-appearance: none;
		appearance:none;
		background:none;
		background:transparent;
		border:none;
		cursor:pointer;
		outline:none;
		padding:14px;
	  width:100%;
		option { padding-right:45px; }
	}
}

.form-wrap {
	background:url('../imgs/footer_img.jpg') center no-repeat;
	background-size:cover;
	color:#fff;
	padding:60px;
	text-align:center;
	.inner-wrap {
		max-width:932px;
		margin:0 auto;
		width:100%;
		h3 {
			font-size: 26px;
			color: #FFFFFF;
			font-weight:800;
			margin-bottom:35px;
			text-transform: uppercase;
		}
		input {
			width:calc(33.33% - 10px);
			@media(max-width:900px) {
				width:100%;
			}
			&[name="email_txt"] {
				margin:0 15px;
				@media(max-width:900px) {
					margin:0 0 15px;
				}
			}
		}
	}
}

.validation-error {
	border: 1px solid $red;
}

.error {
	color: $red;
	font-size: 14px;
	float: right;
	font-family: $standard-font-family;
	text-transform: none;
}
