
  .contact-body {
    display: block;
    &.row {
      margin-left:0;
      margin-right:0;
      //display:flex;
    }
    .col {
      display: inline-block;
      vertical-align: top;
      @media(max-width:800px) {
        width:100%;
        display:block;
      }
      &:nth-child(1) {
        padding-right:65px;
        border-right:0;
        margin-right:0;
        width:59%;
        @media(max-width:800px) { 
          padding-right:0;
          padding-bottom:35px;
          width:100%;
        }
        h1 {
          font-size: 25px;
          color: #00A4C8;
          letter-spacing: 0;
          margin:0 0 40px;
        }
        .btn {
          font-size: 16px;
          color: #FFFFFF;
          letter-spacing: 0;
          background:#00A4C8;
          width:140px;
          text-transform: capitalize;
          text-align:left;
          border-radius:0;
          padding:13px 20px;
          &:after {
            background:#038EAD;
            width:42px;
            height:42px;
            line-height: 40px;
            padding:0;
          }

        }
      }
      &:nth-child(2) {
        padding:40px; 
        width:40%;
        display: inline-block;
        background: #FFFFFF;
        border: 1px solid #DEDEDE;
        border-top:6px solid #E34049;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.10);
        @media(max-width:800px) {
          width:100%;
        }
        @media(max-width:600px) { padding:30px; }
        @media(max-width:450px) { padding:25px; }
        h2 {
          letter-spacing: 0;
          line-height:1;
          margin:0 0 25px;
          font-size: 24px;
          font-family: "proxima-nova";
          font-weight: 700;
          @media(max-width:900px) { font-size:21px; }
          @media(max-width:600px) { font-size:19px; }
          @media(max-width:450px) { font-size:17px; }
        }
        .location {
          margin:10px 0;
          .row {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 1.35;
            margin:0;
            font-style: normal;
            border-top:1px #ddd solid;
            color:#7E7E7E;
            @media(max-width:600px) { font-size:15px; }
            @media(max-width:450px) { 
              flex-direction: column;
              font-size:14px;
             }
            padding:10px 0;
            strong {
              letter-spacing: 0;
              line-height: 1.4;
              display: inline-block;
              vertical-align: top;
              font-style: normal;
              font-weight: 700;
              padding-right:5px;
              @media(max-width:450px) {
                width:100%;
                display: block;
                margin-bottom:5px;
              }
            }
            .value {
              display: inline-block;
              width:calc(100% - 100px);
              vertical-align: top;

              a {
                color:#7E7E7E;
              }
              @media(max-width:450px) {
                width:100%;
                display: block;
              }
            }
          }
          a.btn {
            @media(max-width:450px) {
              display: block;
              width:100%;
              margin-top:10px;
              text-align:center;
            }
          }
        }
      }
    }

  }


