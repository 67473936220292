form.search-frm {
  fieldset {
    border-bottom: 1px solid #D8D8D8;
    display:block;
    margin-bottom:50px;
    padding-bottom:5px;
    label {
      display: inline-block;
      width:40px;
      text-align:center;
      vertical-align:top;
      color:#777777;
      margin-top:3px;
      i {
        font-size:32px;
        @media(max-width:900px) { font-size:30px; }
        @media(max-width:600px) { font-size:26px; }
        @media(max-width:450px) { font-size:23px; }
      }
    }
    input {
      background:none;
      border:0;
      margin:0;
      position: relative;
      display: inline-block;
      width:calc(100% - 45px);
      padding:0 15px;
      vertical-align:top;
      font-size:36px;
      line-height: 1em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media(max-width:900px) { font-size:30px;  }
      @media(max-width:600px) { font-size:26px;  }
      @media(max-width:450px) { font-size:23px; padding:0 10px; }
    }
  }
}

ul.listCalendar {
  display: block;
  li {
    display: block;
    background: #FAFAFA;
    border: 1px solid #D8D8D8;
    margin:0 0 15px;
    padding:18px 18px 18px 5px;
    .cal {
      display: inline-block;
      vertical-align: top;
      width:70px;
      .cal-mon {
        display: block;
        text-align:center;
        font-size: 12px;
        color: #727272;
        letter-spacing: 0;
        line-height: 14px;
        text-transform: uppercase;
        font-family:$secondary-font-family;
        font-weight:800;
        margin: 0 0 4px;
      }
      .cal-day {
        display: block;
        text-align:center;
        font-family:$standard-font-family;
        font-weight:400;
        font-size: 38px;
        color: #000000;
        letter-spacing: -0.3px;
      }
    }
    .txt-wrap {
      display: inline-block;
      vertical-align: top;
      width:calc(100% - 80px);
      border-left:1px solid #D8D8D8;
      padding-left:20px;
      h2 {
        font-family:$standard-font-family;
        font-weight:400;
        font-size: 24px;
        letter-spacing: 0;
        line-height: 1.25;
        margin:0 0 5px;
        a {
          color: #055C8B;
        }
        @media(max-width:900px) { font-size:21px; }
        @media(max-width:600px) { font-size:19px; }
        @media(max-width:450px) { font-size:17px; }
      }
    }
  }
}

.blank-state  {
  background:#FBF4DD;
  padding:10px;
  display: block;
  color:#DDAD00;
  letter-spacing: 0;
  font-family: "proxima-nova";
  font-size: 24px;
  line-height: 1em;
  padding: 15px;
  font-weight: 700;
}

.calendar-details {
  vertical-align: top;
  min-height: 0;
  .calendar-info {
    // 100 / 1160 * 550
    width:47.1%;
    display:inline-block;
    vertical-align: top;
    padding-right:35px;
    @media(max-width:960px) {
      width:100%;
      padding-right:0;
      margin-bottom:35px;
    }
    .calendar-title {
      .cal {
        display: inline-block;
        text-align:center;
        vertical-align: top;
        .cal-mon {
          font-family:$primary-font-family;
          font-weight:800;
          font-size: 20px;
          color: #727272;
          letter-spacing: 0;
          line-height:1;
          text-transform: uppercase;
          display:block;
          margin:0 0 10px;
          @media(max-width:900px) {
            font-size:17px;
          }
          @media(max-width:600px) {
            font-size:16px;
          }
         }
        .cal-day {
          font-size: 74px;
          color: #000000;
          letter-spacing: -0.58px;
          font-weight:300;
          vertical-align: top;
          @media(max-width:900px) {
            font-size:62px;
          }
          @media(max-width:600px) {
            font-size:50px;
          }
        }
      }
      .calendar-title-inner {
        border-left:1px #D8D8D8 solid;
        padding-left:25px;
        margin-left:15px;
        display: inline-block;
        width:calc(100% - 100px);
        padding-top:7.5px;
        padding-bottom:7.5px;
        h1 {
          font-size: 48px;
          color: #055C8B;
          letter-spacing: 0;
          line-height: 1em;
          font-weight:400;
          margin:0 0 10px;
          font-family:$standard-font-family;
          @media(max-width:900px) {
            font-size:40px;
          }
          @media(max-width:600px) {
            font-size:34px;
          }
        }
        @media(max-width:550px) {
          width:100%;
          padding-left:0;
          margin-left:0;
          border-left:0;
          border-top:1px solid #D8D8D8;
          margin-top:25px;
          h1 {
            margin-top:5px;
          }
        }
      }
    }
    .calendar-map {
      width:100%;
      height:290px;
      display:none;
      margin-top:35px;
      .infoWindow {
        a.btn {

        }
      }
    }
  }
  .calendar-txt {
    width:52.49%;
    display: inline-block;
    vertical-align: top;
    p {
      margin:0 0 1.5em;
    }
    @media(max-width:960px) {
      width:100%;
      padding-right:0;
    }

  }
}
