.slideshow {
  position: relative;
  height:720px;
  max-height:calc(100vh - 75px);
  @media(orientation: landscape) {
    min-height:545px;
  }
  overflow: hidden;
  @media(min-width:360px) { max-height:calc(100vh - 100px); }
  .cycle-slideshow {
    position: relative;
    z-index: 100;
    .slide {
      width:100%;
      height:720px;
      background-repeat:no-repeat;
      background-size:cover;
      background-position:center;
      max-height:calc(100vh - 75px);
      @media(orientation: landscape) {
        min-height:545px;
      }
      @media(min-width:360px) { max-height:calc(100vh - 100px); }
      .left-overlay {
        position: absolute;
        opacity: 0.84;
        background-image: linear-gradient(90deg, rgba(0,0,0,0.47) 0%, rgba(0,0,0,0) 50%);
        width:720px;
        left:0;
        top:0;
        z-index: 1000;
        height:720px;
        max-height:calc(100vh - 75px);
        @media(orientation: landscape) {
          min-height:545px;
        }
        @media(min-width:360px) { max-height:calc(100vh - 100px); }
      }
      .inner-wrap {
        display:flex;
        align-items:center;
        height:720px;
        position: relative;
        z-index: 2000;
        max-height:calc(100vh - 75px);
        @media(orientation: landscape) {
          min-height:545px;
        }
        @media(min-width:360px) { max-height:calc(100vh - 100px); }
      }
      .txt-wrap {
        max-width:610px;
        h2 {
          font-size: 52px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-shadow: 0 0 34px rgba(0,0,0,0.72);
          font-weight:700;
          line-height: 1;
          font-family:$primary-font-family;
          @media(max-width:900px) { font-size:47px; }
          @media(max-width:600px) { font-size:42px; }
          @media(max-width:450px) { font-size:36px; }
        }
        p {
          font-size: 24px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 1.35;
          text-shadow: 0 0 15px rgba(0,0,0,0.76);
          margin:10px 0 30px;
          @media(max-width:900px) { font-size:24px; }
          @media(max-width:600px) { font-size:20px; }
          @media(max-width:450px) { font-size:18px; }
        }
        a.btn {

        }
      }
      .img-wrap {
        display:none;
      }
    }
  }
  .banner-paging-wrap {
    position: absolute;
    bottom:25px;
    left:0;
    width:100%;
    height:12px;
    z-index: 200;
    .inner-wrap {
      text-align:center;
    }
    span {
      width:12px;
      height:12px;
      border-radius:50%;
      display: inline-block;
      margin:0 4px;
      background:rgba(#FFFFFF, 0.5);
      text-indent: 999999px;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      &:active {
        top:1px;
      }
      &.cycle-pager-active {
        background:#F8E01C;
      }
    }
  }
}

.sub-slideshow {
  .default-slide {
    background-image:url('../uploads/banners/pages/head-20170227161718.jpg');
    background-size:cover;
  }
  .slideshow {
    height:355px;
    @media(orientation: landscape) {
      min-height:325px;
    }
    @media(max-width:450px) { height:260px; }
  .cycle-slideshow {
    .slide {
      height:355px;
      @media(orientation: landscape) {
        min-height:325px;
      }
      @media(max-width:450px) { height:260px; }
      .inner-wrap { 
        height:355px; 
        @media(orientation: landscape) {
          min-height:325px;
        }
        .txt-wrap {
          padding-top:140px;
        }
        @media(max-width:450px) { 
          height:260px; 
          @media(orientation: landscape) {
            min-height:325px;
          }
          .txt-wrap {
            padding-top:100px;
          }
        }
      }
      }
    }
  }
}
