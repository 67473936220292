header {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:90px;
    overflow: visible;
    z-index: 500;
    padding:15px 0 0;
    border-bottom:1px rgba(#fff, 0.47) solid;
    @media(max-width:$mobile-nav) {
      border-bottom:0;
    }
    .overlay {
      opacity: 0.8;
      width:100%;
      height:184.26px;
      position: absolute;
      top:0;
      left:0;
      z-index: 1000;
      background: -moz-linear-gradient(top,  rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.65) 50%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.65) 50%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.65) 50%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3000000', endColorstr='#007db9e8',GradientType=0 ); /* IE6-9 */
    }
    .top {
      position: relative;
      z-index: 2000;
      #logo {
        img {
          width:132px;
          @media(max-width:900px) { width:112px;  } 
          @media(max-width:600px) { width:92px; } 
          @media(max-width:450px) { width:72px;  } 
        }
      }
    }
    ul {
      float:right;
      margin:20px 0;
      @media(max-width:$mobile-nav) {
        display: none;
      }
      li {
        display: inline-block;
        position: relative;
        a {
          color:#fff;
          padding:10px;
          &:hover {
            color:#fff;
          }
          &.selected {
            background:#fff;
            color:#155C8C;
          }
        }
        &.active {
          text-decoration: underline;
        }
        div.parent-sub {
          position: absolute;
          top: 32px;
          z-index: 9999;
          background: #fff;
          box-shadow: 0 4px 4px 0 rgba(0,0,0,0.20);
          font-size: 14px;
          left: 0;
          padding:25px 20px;
          box-sizing: border-box;
          width:544px;
          text-align: left;
          display: none;
          vertical-align: top;
          ul {
            float: none;
            width:175px;
            display: inline-block;
            margin:0;
            vertical-align: top;
            padding:10px 0;
            li {
              display: block;
              margin-top:10px;
              line-height: 1.2;
              &:nth-child(1) { margin-top:0; }
              a {
                color:#07A7CA;
                padding:0;
              }
            }
          }
          span.sub-nav-txt {
            width:calc(100% - 175px);
            display: inline-block;
            vertical-align: top;
            border-left:1px #D8D8D8 solid;
            padding-left:30px;
            span.sub-nav-title {
              display: block;
              font-family: $primary-font-family;
              font-size: 26px;
              color: #155C8C;
              letter-spacing: 0;
              line-height: 1.1em;
              font-weight:800;
              margin:10px 0 15px;
  
            }
            span.sub-nav-desc {
              font-size: 15px;
              color: #9B9B9B;
              letter-spacing: 0;
              line-height: 1.333333em;
              font-weight:400;
              margin:0 0 10px;
              display: block;
            }
          }
        }
      }
    }
  }
  
  
  /* Navigation Button */
  #mobile-burger-btn {
    display:none;
    width: 40px;
    height: 40px;
    margin:0;
    cursor: pointer;
    color:white;
    background:#0A3451;
    z-index: 100;
    text-align:center;
    transition: right 0.5s;
    i {
      font-size:28px;
      position: relative;
      top:5px;
      &:nth-child(2) {
        display:none;
      }
    }
    @media(max-width:$mobile-nav) {
      display: inline-block;
      position: fixed;
      top:15px;
      right:15px;
      z-index: 9999;
    }
    &.menu-open {
      right:250px;
      i {
        &:nth-child(1) { display: none; }
        &:nth-child(2) { display: inline-block; }
      }
    }
  }
  