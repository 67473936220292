.loader {
  height:525px;
  display: block;
  width:100%;
  background:#F4F4F4 url('../imgs/productsLoader.gif') center no-repeat;
  z-index: 200;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
  @media(max-width:600px) {
    height:350px;
  }
}

.leftBtn-gallery {
  position:absolute;
  top:215px;
  cursor:pointer;
  z-index: 300;
  left:0;
  @media(max-width:600px) {
    top: 98px;
  }
  i {
    font-size:60px;
    display: inline-block;
    height:124px;
    line-height: 124px;
    width:53px;
    color:#fff;

  }
  &:active {
    top:216px;
    @media(max-width:600px) {
      top: 99px;
    }
  }
}

.rightBtn-gallery {
  @extend .leftBtn-gallery;
  left: auto;
  right:0;
}


.gallery {
  text-align:center;
  overflow:hidden;
  margin:30px auto 0;
  width:100%;
  position:relative;
  background:#F4F4F4;
  border-top:50px #F4F4F4 solid;
  border-bottom:50px #F4F4F4 solid;
  .slideshow-wrap {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    z-index: 100;
    .slideshow-inner-img {
      background-position:center;
      background-repeat:no-repeat;
      background-size:cover;
      cursor: pointer;
      position:relative;
      display:inline-block;
      margin:0;
      text-overflow: hidden;
      background:#000;
      border-left:25px #F4F4F4 solid;
      border-right:25px #F4F4F4 solid;
      img {
        opacity:0.30;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        position: relative;
        z-index: 10;
        max-width:none;
        height:525px;
        @media(max-width:600px) {
          height:350px;
        }
      }
      iframe {
        height:525px;
        width:933px;
        @media(max-width:600px) {
          height:350px;
        }
      }
    }
    div.slick-active {
      img { opacity:1; }
    }
  }
}


@media (max-width:400px) {
  .leftBtn { left: 0 !important; top: 44% !important;}
  .rightBtn { right: 0 !important; top: 44% !important;}
}

.gallery-details-item {
  margin:0 auto;
  img {
    padding:5px;
    margin:2.5px 0;
    width:100%;
    max-width:none;
    &:hover {
      opacity:0.7;
    }
  }
  .item {
    overflow: hidden;
  }
  &:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

/* Customer Gallery */
.customergallery {
  background: #1C1C1C;
  padding:60px 0;
  .inner-wrap {
    border:1px #434343 solid;
    overflow: hidden;
    height: 270px;
    @media (max-width: $mobile-nav) { height: 220px; }
    @media (max-width: 750px){ height: 285px; }
    @media (max-width: 510px){ height: 255px; }
  }
  .txt-wrap {
    vertical-align: top;
    width:277px;
    height: 270px;
    display: inline-block;
    padding:30px;
    background: repeating-linear-gradient(135deg, #111, #111 20px, #000 20px, #000 40px);
    @media (max-width: $mobile-nav) {
      height: 220px;
      width: 220px;
    }
    @media (max-width: 750px){
      width: 100%;
      height: 85px;
      padding: 20px 30px;
    }
    @media (max-width: 600px) {
      padding: 20px;
    }
    h1 {
      font-size: 50px;
      color: #F8E01C;
      letter-spacing: 0;
      line-height: 48px;
      @media (max-width: 750px) {
        font-size: 40px;
        float: left;
      }
      @media (max-width: 600px) {
        font-size: 30px;
      }
    }
    .btn-wrap {
      margin:20px 0;
      @media (max-width: 750px) {
        float: right;
        margin: 5px 0;
      }
      a.btn {
        margin-left: 5px;
        padding: 10px 15px;
        display: inline-block;
        background: #3B3B3B;
        border-radius: 0;
        &:nth-child(1) {
          margin-left: 0;
        }
        i.fa {
          color:#fff;
          margin:0;
          line-height: 1em;
          display: block;
        }
        text-align: center;
      }
    }
    h2 {
      font-size:15px;
      color:#fff;
      text-transform: uppercase;
      @media (max-width: $mobile-nav) {
        display: none;
      }
    }

  }
  .img-wrap {
    width:calc(100% - 277px);
    display: inline-block;
    ul {
      li {
        vertical-align: top;
        display: inline-block;
        width:272px;
        overflow: hidden;
        @media (max-width: $mobile-nav) { width: 220px; }
        @media (max-width: 600px) { width: 200px; }
        @media (max-width: 510px) { width: 170px; }
        a {
          height:270px;
          display: block;
          width:100%;
          background-size:cover;
          background-repeat:no-repeat;
          background-position:center;
          @media (max-width: $mobile-nav) { height: 220px; }
          @media (max-width: 600px) { height: 200px; }
          @media (max-width: 510px) { height: 170px; }
          img {
            height:270px;
            max-width: none;
            display: none;
            @media (max-width: $mobile-nav) {
              height: 220px;
              width: 220px;
            }
            @media (max-width: 600px) {
              height: 200px;
              width: 200px;
            }
            @media (max-width: 510px) {
              height: 170px;
              width: 170px;
            }
          }
        }
      }
    }

  }
}

.list-images-thumbs {
  margin: 30px 0 50px;
  h3 {
    font-weight: 700;
    margin: 5px 0;
  }
  ul {
    display: block;
    li {
      margin: 0 15px 15px 0;
      height: 165px;
      display: inline-block;
      overflow: hidden;
      width:calc(33% - 11px);
      height: 165px;
      &:nth-child(3n+3) {
        margin-right:0;
      }
      @media(max-width:650px) {
        width:calc(50% - 10px);
        &:nth-child(3n+3) {
          clear: none;
          margin-right:15px;
        }
        &:nth-child(2n+2) {
          margin-right:0;
        }
      }
      @media(max-width:400px) {
        width:100%;
        &:nth-child(3n+3) {
          margin-right:0;
        }
        &:nth-child(2n+2) {
          margin-right:0;
        }
      }
      img{
        max-width: 150%;
      }
      a.thumb {
        display:block;
        width:100%;
        height: 165px;
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center;
        img {
          opacity:0;
          width: 100%;
        }
      }
    }
  }
}
