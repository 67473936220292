html {
  box-sizing: border-box;
}

body {
  font:16px/1.5em $standard-font-family;
  font-weight:400;
  background-color:#0E3550;
  @media (max-width:900px) { font-size:15px; line-height: 1.5em; }
  @media (max-width:600px) { font-size:14px; line-height: 1.5em; }
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}

strong {
  font-weight:700;
}


fieldset {
  border:0;
  margin:0;
  padding:0;
}

img {
  max-width:100%;
}

.center { text-align:center; }
.clear { clear:both; }



#page-wrap {
  position: relative;
}

#page-wrap-inner {
  position: relative;
  right: 0;
  z-index: 100;
  width: 100%;
  transition: right 0.3s ease;
  background:#FBFBFB;
  min-height: 100vh;
  a {
    position: relative;
    &:active {
      top:1px;
    }
  }
  &.push {
    right: 235px;
    .page-header { right: 235px; }
  }
}

#productFeatureLoader  { display:none; }


#loader {
  display:block;
  margin:35px auto;
}

#zero {
  display:none;
  text-align:center;
  color:#fff;
  padding:35px 0;
}

.default-pointer {
  cursor: default;
}

.hide-from-mobile {
  display: block;
  @media(max-width:900px) {
    display:none;
  }
}

.show-only-on-mobile {
  display: none;
  @media(max-width:900px) {
    clear:both;
    display:inline-block;
  }
}

.hr {
  border-top:1px #D4CFCF solid;
}


.tag {
  font-family:$secondary-font-family;
  font-size:12px;
  font-weight:900;
  display: inline-block;
  text-transform: uppercase;
  color:#fff;
  background:$red;
  padding:0 10px;
  line-height: 1.76em;
}


